import axios from 'axios';

async function setAccessToken(token) {
  axios.defaults.headers.common.Authorization = `bearer ${token}`;
}

export default {
  axios,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setAccessToken,
};


export const AppContext = () => {
  //use for data transfer one screen to another screens
  };