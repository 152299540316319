import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
// import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
// import IconButton from '@mui/material/IconButton';
// import Stack from '@mui/material/Stack';
// import SvgIcon from '@mui/material/SvgIcon';
//import useMediaQuery from '@mui/material/useMediaQuery';

// import { Logo } from 'src/components/logo';
// import { RouterLink } from 'src/components/router-link';
// import { usePathname } from 'src/hooks/use-pathname';
import { useWindowScroll } from 'src/hooks/use-window-scroll';
// import { paths } from 'src/paths';

// import { PagesPopover } from './pages-popover';
// import { TopNavItem } from './top-nav-item';

// const items = [
//   {
//     title: 'Components',
//     path: paths.components.index
//   },
//   {
//     title: 'Pages',
//     popover: <PagesPopover />
//   },
//   {
//     title: 'Docs',
//     path: paths.docs,
//     external: true
//   }
// ];

// const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  // const { onMobileNavOpen } = props;
  // const pathname = usePathname();
  // const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [elevate, setElevate] = useState(false);
  const offset = 64;
  const delay = 100;

  const handleWindowScroll = useCallback(() => {
    if (window.scrollY > offset) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  }, []);

  useWindowScroll({
    handler: handleWindowScroll,
    delay
  });

  return (
    <Box
      component="header"
      sx={{
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        pt: 2,
        zIndex: (theme) => theme.zIndex.appBar
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: 'transparent',
          borderRadius: 2.5,
          boxShadow: 'none',
          transition: (theme) => theme.transitions.create('box-shadow, background-color', {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          ...(elevate && {
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.90),
            boxShadow: 8
          })
        }}
      >

      </Container>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
