import { settings } from "nprogress";
import { store } from "./store";

export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register'
    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      classic: '/auth-demo/forgot-password/classic',
      modern: '/auth-demo/forgot-password/modern'
    },
    login: {
      classic: '/auth-demo/login/classic',
      modern: '/auth-demo/login/modern'
    },
    register: {
      classic: '/auth-demo/register/classic',
      modern: '/auth-demo/register/modern'
    },
    resetPassword: {
      classic: '/auth-demo/reset-password/classic',
      modern: '/auth-demo/reset-password/modern'
    },
    verifyCode: {
      classic: '/auth-demo/verify-code/classic',
      modern: '/auth-demo/verify-code/modern'
    }
  },

  dashboard: {
    index: '/dashboard',
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/customers',
      details: '/dashboard/customers/:customerId',
      edit: '/dashboard/customers/:customerId/edit'
    },
    store: {
      storeList: '/dashboard/store',
      createStore: '/dashboard/store/createStore',
    },
    location: {
      locationList: '/dashboard/location',
      createLocation: '/dashboard/location/createLocation',
    },
    paymentType: {
      paymentTypeList: '/dashboard/paymentType',
      createPaymentType: '/dashboard/paymentType/createPaymentType',
    },
    country: {
      index: '/dashboard/country',
      createCountry: '/dashboard/country/createCountry'
    },
    region: {
      regionList: '/dashboard/region',
      createRegion: '/dashboard/region/createRegion',
    },
    tax: {
      taxList: '/dashboard/tax',
      createTax: '/dashboard/tax/createTax',
    },
    expensesType: {
      expensesTypeList: '/dashboard/expensesType',
      createExpensesType: '/dashboard/expensesType/createExpensesType',
    },
    receivedType: {
      receivedTypeList: '/dashboard/receivedType',
      createReceivedType: '/dashboard/receivedType/createReceivedType',
    },
    loyaltyPoint: {
      loyaltyPointList: '/dashboard/loyaltyPoint',
      createLoyaltyPoint: '/dashboard/loyaltyPoint/createLoyaltyPoint',
    },
    giftCard: {
      giftCardList: '/dashboard/giftCard',
      createGiftCard: '/dashboard/giftCard/createGiftCard',
    },
    discount: {
      discountList: '/dashboard/discount',
      createDiscount: '/dashboard/discount/createDiscount',
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    },
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    kanban: '/dashboard/kanban',
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },

  //restaurants
  restaurant: {
    index: '/restaurant',
    menuCategory: {
      menuCategoryList: '/restaurant/menuCategory',
      createMenuCategory: '/restaurant/menuCategory/createMenuCategory',
    },
    unit: {
      unitList: '/restaurant/unit',
      createUnit: '/restaurant/unit/createUnit',
    },
    attributes: {
      attributesList: '/restaurant/attributes',
      createAttributes: '/restaurant/attributes/createAttributes',
    },
    floor: {
      floorList: '/restaurant/floor',
      createFloor: '/restaurant/floor/createFloor',
    },
    menu: {
      menuList: '/restaurant/menu',
      createMenu: '/restaurant/menu/createMenu',
    },
    promotionReward: {
      promotionRewardList: '/restaurant/promotionReward',
      createPromotionReward: '/restaurant/promotionReward/createPromotionReward',
    },
    loyaltyPointReward: {
      loyaltyPointRewardList: '/restaurant/loyaltyPointReward',
      createLoyaltyPointReward: '/restaurant/loyaltyPointReward/createLoyaltyPointReward',
    },
    //Customer

    customerCategory: {
      customerCategoryList: '/restaurant/customerCategory',
      createCustomerCategory: '/restaurant/customerCategory/createCustomerCategory',
    },
    customer: {
      customerList: '/restaurant/customer',
      createCustomer: '/restaurant/customer/createCustomer',
    },
    custGiftCard: {
      custGiftCardList: '/restaurant/custGiftCard',
      createCustGiftCard: '/restaurant/custGiftCard/createCustGiftCard',
    },
    salesReports: {
      salesByDay: '/restaurant/salesReports',
      salesByMenu: '/restaurant/salesReports/salesByMenu',
      salesByPaymentType: '/restaurant/salesReports/salesByPaymentType',
      salesByCategory: '/restaurant/salesReports/salesByCategory',
    },

    fileManager: '/restaurant/file-manager',
  },

  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
