export const users = [
  {
    id: '1',
    avatar: '/assets/avatars/avatar-anika-visser.png',
    email: 'demo@devias.io',
    name: 'Anika Visser',
    password: 'Password123!',
    plan: 'Premium'
  },
  {
    id: '2',
    avatar: '/assets/avatars/avatar-anika-visser.png',
    email: 'harisul44@gmail.com',
    name: 'Anika Visser',
    password: '123',
    plan: 'Premium'
  }
];
