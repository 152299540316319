import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';

const sections = [
  {
    title: 'Menu',
    items: [
      {
        title: 'Browse Components',
        path: paths.components.index
      },
      {
        title: 'Documentation',
        external: true,
        path: paths.docs
      }
    ]
  },
  {
    title: 'Legal',
    items: [
      {
        title: 'Terms & Conditions',
        path: '#'
      },
      {
        title: 'License',
        path: '#'
      },
      {
        title: 'Contact',
        path: '#'
      }
    ]
  },
  {
    title: 'Social',
    items: [
      {
        title: 'Instagram',
        path: '#'
      },
      {
        title: 'LinkedIn',
        path: '#'
      }
    ]
  }
];

export const Footer = (props) => (
  <Box
    {...props}>
  </Box>
);
