import axios from "axios";

export const Url = () => {
};


Url.baseUrl = 'https://api01.mypossystem.in/api';

//Url.baseUrl='https://localhost:7148/api';

