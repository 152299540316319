/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { Url } from "./http";
import httpServices from "./httpServices";

//const apiUrl = "https://localhost:7148/api";

const apiUrl = Url.baseUrl;

export class DataHelper {
    getList = (controllerName, pageSize, pageNumber, orderBy, orderDirection, criteria) => {
        let url = `${apiUrl}/${controllerName}/GetList?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (orderDirection) url += `&orderDirection=${orderDirection}`;
        if (criteria) url += `&criteria=${encodeURIComponent(JSON.stringify(criteria))}`;
        return httpServices.get(url);
    }

    save = (controllerName, dataObject) => {
        return httpServices.post(`${apiUrl}/${controllerName}/Save`, { dataObj: JSON.stringify(dataObject) });
    }

    SaveLoyaltyPoint = (controllerName, dataObject) => {
        return httpServices.post(`${apiUrl}/${controllerName}/SaveLoyaltyPoint`, { dataObj: JSON.stringify(dataObject) });
    }


    delete = (controllerName, dataObject) => {
        return httpServices.post(`${apiUrl}/${controllerName}/Delete`, { dataObj: JSON.stringify(dataObject) },);
    }

    closefiscalyear = (formID, dataObject) => {
        const config = {
            headers: {
                FormId: formID,
            }
        };
        return httpServices.post(`${apiUrl}/${this.controller}/closefiscalyear`, { dataObj: JSON.stringify(dataObject) }, config);
    }

    getNextCode = (controllerName) => {
        return httpServices.get(`${apiUrl}/${controllerName}/GetNextCode`,);
    }

    checkCodeExist = (formID, code) => {
        const config = {
            headers: {
                FormId: formID,
            }
        };
        return httpServices.get(`${apiUrl}/${this.controller}/CheckCodeExist?code=${code}`, config);
    }

    getById(controllerName, id) {
        return httpServices.get(`${apiUrl}/${controllerName}/GetByID?ID=${id}`,);
    }

    //Reports

    SalesByDay = (controllerName, pageSize, pageNumber, locationId, orderBy, fromDate, toDate) => {
        let url = `${apiUrl}/${controllerName}/SalesByDay?pageSize=${pageSize}&pageNumber=${pageNumber}&locationId=${locationId}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (fromDate) url += `&fromDate=${fromDate}`;
        if (toDate) url += `&toDate=${toDate}`;
        return httpServices.get(url);
    }

    SalesByMenu = (controllerName, pageSize, pageNumber, locationId, orderBy, fromDate, toDate) => {
        let url = `${apiUrl}/${controllerName}/SalesByMenu?pageSize=${pageSize}&pageNumber=${pageNumber}&locationId=${locationId}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (fromDate) url += `&fromDate=${fromDate}`;
        if (toDate) url += `&toDate=${toDate}`;
        return httpServices.get(url);
    }
    SalesByPaymentType = (controllerName, pageSize, pageNumber, locationId, orderBy, fromDate, toDate) => {
        let url = `${apiUrl}/${controllerName}/SalesByPaymentType?pageSize=${pageSize}&pageNumber=${pageNumber}&locationId=${locationId}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (fromDate) url += `&fromDate=${fromDate}`;
        if (toDate) url += `&toDate=${toDate}`;
        return httpServices.get(url);
    }
    SalesByCategory = (controllerName, pageSize, pageNumber, locationId, orderBy, fromDate, toDate) => {
        let url = `${apiUrl}/${controllerName}/SalesByCategory?pageSize=${pageSize}&pageNumber=${pageNumber}&locationId=${locationId}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (fromDate) url += `&fromDate=${fromDate}`;
        if (toDate) url += `&toDate=${toDate}`;
        return httpServices.get(url);
    }
}
